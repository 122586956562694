import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap'

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#ffffff', 1)
scene.add(ambientLight)

const loadingScreen = document.getElementById('loadingScreen')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        loadingScreen.classList.add('ended')
        document.getElementById('enter').classList.add('show')
        gsap.delayedCall(1, () =>
        {
            loadingScreen.classList.add('hide')
        })
        
    },
    // Progress
    (itemURL, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
    }
)

/**
 * ----------------GLTF------------------
 */
const glTFLoader = new GLTFLoader(loadingManager)

let mapMesh = null

glTFLoader.load(
    '/Assets/map.gltf',
    (gltf) =>
    {
        mapMesh = gltf.scene
        mapMesh.rotation.x = Math.PI / 2
        scene.add(mapMesh)
    }
)

/**
 * ---------------------OGG------------------------------
 */

const listener = new THREE.AudioListener()

const rec1Sound = new THREE.PositionalAudio(listener)
const rec2Sound = new THREE.PositionalAudio(listener)
const rec3Sound = new THREE.PositionalAudio(listener)
const rec4Sound = new THREE.PositionalAudio(listener)
const rec5Sound = new THREE.PositionalAudio(listener)
const rec6Sound = new THREE.PositionalAudio(listener)
const rec7Sound = new THREE.PositionalAudio(listener)
const rec8Sound = new THREE.PositionalAudio(listener)
const rec9Sound = new THREE.PositionalAudio(listener)
const rec10Sound = new THREE.PositionalAudio(listener)
const rec11Sound = new THREE.PositionalAudio(listener)
const rec12Sound = new THREE.PositionalAudio(listener)
const rec13Sound = new THREE.PositionalAudio(listener)
const rec14Sound = new THREE.PositionalAudio(listener)
const rec15Sound = new THREE.PositionalAudio(listener)
const rec16Sound = new THREE.PositionalAudio(listener)
const rec17Sound = new THREE.PositionalAudio(listener)
const rec18Sound = new THREE.PositionalAudio(listener)
const rec19Sound = new THREE.PositionalAudio(listener)
const rec20Sound = new THREE.PositionalAudio(listener)
const tree1Sound = new THREE.PositionalAudio(listener)
const tree2Sound = new THREE.PositionalAudio(listener)
const tree3Sound = new THREE.PositionalAudio(listener)
const tree4Sound = new THREE.PositionalAudio(listener)
const tree5Sound = new THREE.PositionalAudio(listener)
const tree6Sound = new THREE.PositionalAudio(listener)
const tree7Sound = new THREE.PositionalAudio(listener)
const tree8Sound = new THREE.PositionalAudio(listener)
const tree9Sound = new THREE.PositionalAudio(listener)
const tree10Sound = new THREE.PositionalAudio(listener)
const tree11Sound = new THREE.PositionalAudio(listener)
const tree12Sound = new THREE.PositionalAudio(listener)
const tree13Sound = new THREE.PositionalAudio(listener)
const tree14Sound = new THREE.PositionalAudio(listener)
const tree15Sound = new THREE.PositionalAudio(listener)
const tree16Sound = new THREE.PositionalAudio(listener)
const tree17Sound = new THREE.PositionalAudio(listener)
const tree18Sound = new THREE.PositionalAudio(listener)
const tree19Sound = new THREE.PositionalAudio(listener)
const tree20Sound = new THREE.PositionalAudio(listener)

const audioLoader = new THREE.AudioLoader(loadingManager)

let refDist = 0.02

audioLoader.load(
    '/Assets/sounds/Recording1.ogg', 
    (buffer) =>
    {
        rec1Sound.setBuffer(buffer)
        rec1Sound.setRefDistance(refDist)
        rec1Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording2.ogg',
    (buffer) =>
    {
        rec2Sound.setBuffer(buffer)
        rec2Sound.setRefDistance(refDist)
        rec2Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording3.ogg',
    (buffer) =>
    {
        rec3Sound.setBuffer(buffer)
        rec3Sound.setRefDistance(refDist)
        rec3Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording4.ogg',
    (buffer) =>
    {
        rec4Sound.setBuffer(buffer)
        rec4Sound.setRefDistance(refDist)
        rec4Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording5.ogg',
    (buffer) =>
    {
        rec5Sound.setBuffer(buffer)
        rec5Sound.setRefDistance(refDist)
        rec5Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording6.ogg',
    (buffer) =>
    {
        rec6Sound.setBuffer(buffer)
        rec6Sound.setRefDistance(refDist)
        rec6Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording7.ogg',
    (buffer) =>
    {
        rec7Sound.setBuffer(buffer)
        rec7Sound.setRefDistance(refDist)
        rec7Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording8.ogg',
    (buffer) =>
    {
        rec8Sound.setBuffer(buffer)
        rec8Sound.setRefDistance(refDist)
        rec8Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording9.ogg',
    (buffer) =>
    {
        rec9Sound.setBuffer(buffer)
        rec9Sound.setRefDistance(refDist)
        rec9Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording10.ogg',
    (buffer) =>
    {
        rec10Sound.setBuffer(buffer)
        rec10Sound.setRefDistance(refDist)
        rec10Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording11.ogg',
    (buffer) =>
    {
        rec11Sound.setBuffer(buffer)
        rec11Sound.setRefDistance(refDist)
        rec11Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording12.ogg',
    (buffer) =>
    {
        rec12Sound.setBuffer(buffer)
        rec12Sound.setRefDistance(refDist)
        rec12Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording13.ogg',
    (buffer) =>
    {
        rec13Sound.setBuffer(buffer)
        rec13Sound.setRefDistance(refDist)
        rec13Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording14.ogg',
    (buffer) =>
    {
        rec14Sound.setBuffer(buffer)
        rec14Sound.setRefDistance(refDist)
        rec14Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording15.ogg',
    (buffer) =>
    {
        rec15Sound.setBuffer(buffer)
        rec15Sound.setRefDistance(refDist)
        rec15Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording16.ogg',
    (buffer) =>
    {
        rec16Sound.setBuffer(buffer)
        rec16Sound.setRefDistance(refDist)
        rec16Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording17.ogg',
    (buffer) =>
    {
        rec17Sound.setBuffer(buffer)
        rec17Sound.setRefDistance(refDist)
        rec17Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording18.ogg',
    (buffer) =>
    {
        rec18Sound.setBuffer(buffer)
        rec18Sound.setRefDistance(refDist)
        rec18Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Recording19.ogg',
    (buffer) =>
    {
        rec19Sound.setBuffer(buffer)
        rec19Sound.setRefDistance(refDist)
        rec19Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree1.ogg', 
    (buffer) =>
    {
        tree1Sound.setBuffer(buffer)
        tree1Sound.setRefDistance(refDist)
        tree1Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree2.ogg',
    (buffer) =>
    {
        tree2Sound.setBuffer(buffer)
        tree2Sound.setRefDistance(refDist)
        tree2Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree3.ogg',
    (buffer) =>
    {
        tree3Sound.setBuffer(buffer)
        tree3Sound.setRefDistance(refDist)
        tree3Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree4.ogg',
    (buffer) =>
    {
        tree4Sound.setBuffer(buffer)
        tree4Sound.setRefDistance(refDist)
        tree4Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree5.ogg',
    (buffer) =>
    {
        tree5Sound.setBuffer(buffer)
        tree5Sound.setRefDistance(refDist)
        tree5Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree6.ogg',
    (buffer) =>
    {
        tree6Sound.setBuffer(buffer)
        tree6Sound.setRefDistance(refDist)
        tree6Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree7.ogg',
    (buffer) =>
    {
        tree7Sound.setBuffer(buffer)
        tree7Sound.setRefDistance(refDist)
        tree7Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree8.ogg',
    (buffer) =>
    {
        tree8Sound.setBuffer(buffer)
        tree8Sound.setRefDistance(refDist)
        tree8Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree9.ogg',
    (buffer) =>
    {
        tree9Sound.setBuffer(buffer)
        tree9Sound.setRefDistance(refDist)
        tree9Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree10.ogg',
    (buffer) =>
    {
        tree10Sound.setBuffer(buffer)
        tree10Sound.setRefDistance(refDist)
        tree10Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree11.ogg',
    (buffer) =>
    {
        tree11Sound.setBuffer(buffer)
        tree11Sound.setRefDistance(refDist)
        tree11Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree12.ogg',
    (buffer) =>
    {
        tree12Sound.setBuffer(buffer)
        tree12Sound.setRefDistance(refDist)
        tree12Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree13.ogg',
    (buffer) =>
    {
        tree13Sound.setBuffer(buffer)
        tree13Sound.setRefDistance(refDist)
        tree13Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree14.ogg',
    (buffer) =>
    {
        tree14Sound.setBuffer(buffer)
        tree14Sound.setRefDistance(refDist)
        tree14Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree15.ogg',
    (buffer) =>
    {
        tree15Sound.setBuffer(buffer)
        tree15Sound.setRefDistance(refDist)
        tree15Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree16.ogg',
    (buffer) =>
    {
        tree16Sound.setBuffer(buffer)
        tree16Sound.setRefDistance(refDist)
        tree16Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree17.ogg',
    (buffer) =>
    {
        tree17Sound.setBuffer(buffer)
        tree17Sound.setRefDistance(refDist)
        tree17Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree18.ogg',
    (buffer) =>
    {
        tree18Sound.setBuffer(buffer)
        tree18Sound.setRefDistance(refDist)
        tree18Sound.setLoop(true)
})
audioLoader.load(
    '/Assets/sounds/Tree19.ogg',
    (buffer) =>
    {
        tree19Sound.setBuffer(buffer)
        tree19Sound.setRefDistance(refDist)
        tree19Sound.setLoop(true)
})


/**
 * ---------------------THREEJS MESH-----------------------------
 */


const xMat = new THREE.TextureLoader().load('Assets/x.png')
const yMat = new THREE.TextureLoader().load('Assets/y.png')

const locGeo = new THREE.SphereGeometry(0.008,8,8)
const locMat = new THREE.MeshBasicMaterial({color:0xF48665, map: xMat})
const treeMat = new THREE.MeshBasicMaterial({color:0x467749, map:yMat})

const rec1 = new THREE.Mesh(locGeo, locMat)
rec1.name = "rec1"
rec1.position.set(-1.01,-0.16,0)
rec1.add(rec1Sound)
scene.add(rec1)

const rec2 = new THREE.Mesh(locGeo, locMat)
rec2.name = "rec2"
rec2.add(rec2Sound)
rec2.position.set(-0.8,0.26,0)
scene.add(rec2)

const rec3 = new THREE.Mesh(locGeo, locMat)
rec3.name = "rec3"
rec3.add(rec3Sound)
rec3.position.set(-1.38,0.48,0)
scene.add(rec3)

const rec4 = new THREE.Mesh(locGeo, locMat)
rec4.name = "rec4"
rec4.add(rec4Sound)
rec4.position.set(-1.55,-0.34,0)
scene.add(rec4)

const rec5 = new THREE.Mesh(locGeo, locMat)
rec5.name = "rec5"
rec5.add(rec5Sound)
rec5.position.set(-1.58,-0.64,0)
scene.add(rec5)

const rec6 = new THREE.Mesh(locGeo, locMat)
rec6.name = "rec6"
rec6.add(rec6Sound)
rec6.position.set(-0.52,-0.78,0)
scene.add(rec6)

const rec7 = new THREE.Mesh(locGeo, locMat)
rec7.name = "rec7"
rec7.add(rec7Sound)
rec7.position.set(0.39,-0.18,0)
scene.add(rec7)

const rec8 = new THREE.Mesh(locGeo, locMat)
rec8.name = "rec8"
rec8.add(rec8Sound)
rec8.position.set(0.835,0.855,0)
scene.add(rec8)

const rec9 = new THREE.Mesh(locGeo, locMat)
rec9.name = "rec9"
rec9.position.set(0.11,0.94,0)
rec9.add(rec9Sound)
scene.add(rec9)

const rec10 = new THREE.Mesh(locGeo, locMat)
rec10.name = "rec10"
rec10.position.set(1.2,-1,0)
rec10.add(rec10Sound)
scene.add(rec10)

const rec11 = new THREE.Mesh(locGeo, locMat)
rec11.name = "rec11"
rec11.position.set(0.85,-0.7,0)
rec11.add(rec11Sound)
scene.add(rec11)

const rec12 = new THREE.Mesh(locGeo, locMat)
rec12.name = "rec12"
rec12.position.set(0.3,-0.35,0)
rec12.add(rec12Sound)
scene.add(rec12)

const rec13 = new THREE.Mesh(locGeo, locMat)
rec13.name = "rec13"
rec13.position.set(0.75,2.08,0)
rec13.add(rec13Sound)
scene.add(rec13)

const rec14 = new THREE.Mesh(locGeo, locMat)
rec14.name = "rec14"
rec14.position.set(1.9,1.25,0)
rec14.add(rec14Sound)
scene.add(rec14)

const rec15 = new THREE.Mesh(locGeo, locMat)
rec15.name = "rec15"
rec15.position.set(-1.1,-0.83,0)
rec15.add(rec15Sound)
scene.add(rec15)

const rec16 = new THREE.Mesh(locGeo, locMat)
rec16.name = "rec16"
rec16.position.set(-0.45,-2.15,0)
rec16.add(rec16Sound)
scene.add(rec16)

const rec17 = new THREE.Mesh(locGeo, locMat)
rec17.name = "rec17"
rec17.position.set(0.71,-0.52,0)
rec17.add(rec17Sound)
scene.add(rec17)

const rec18 = new THREE.Mesh(locGeo, locMat)
rec18.name = "rec18"
rec18.position.set(0.35,1.01,0)
rec18.add(rec18Sound)
scene.add(rec18)

const rec19 = new THREE.Mesh(locGeo, locMat)
rec19.name = "rec19"
rec19.position.set(0.1,1.5,0)
rec19.add(rec19Sound)
scene.add(rec19)

const rec20 = new THREE.Mesh(locGeo, locMat)
rec20.name = "rec20"
rec20.position.set(0,-0.5,0)
rec20.add(rec20Sound)
scene.add(rec20)

//TREES

const tree1 = new THREE.Mesh(locGeo, treeMat)
tree1.name = "tree1"
tree1.position.set(0.74,2.01,0)
tree1.add(tree1Sound)
scene.add(tree1)

const tree2 = new THREE.Mesh(locGeo, treeMat)
tree2.name = "tree2"
tree2.position.set(1.85,1.18,0)
tree2.add(tree2Sound)
scene.add(tree2)

const tree3 = new THREE.Mesh(locGeo, treeMat)
tree3.name = "tree3"
tree3.position.set(-1,-0.88,0)
tree3.add(tree3Sound)
scene.add(tree3)

const tree4 = new THREE.Mesh(locGeo, treeMat)
tree4.name = "tree4"
tree4.position.set(-0.3,-1.8,0)
tree4.add(tree4Sound)
scene.add(tree4)

const tree5 = new THREE.Mesh(locGeo, treeMat)
tree5.name = "tree5"
tree5.position.set(0.69,-0.51,0)
tree5.add(tree5Sound)
scene.add(tree5)

const tree6 = new THREE.Mesh(locGeo, treeMat)
tree6.name = "tree6"
tree6.position.set(0.3,0.87,0)
tree6.add(tree6Sound)
scene.add(tree6)

const tree7 = new THREE.Mesh(locGeo, treeMat)
tree7.name = "tree7"
tree7.position.set(0.25,1.5,0)
tree7.add(tree7Sound)
scene.add(tree7)

const tree8 = new THREE.Mesh(locGeo, treeMat)
tree8.name = "tree8"
tree8.position.set(-0.4,1.55,0)
tree8.add(tree8Sound)
scene.add(tree8)

const tree9 = new THREE.Mesh(locGeo, treeMat)
tree9.name = "tree9"
tree9.position.set(-0.6,1.05,0)
tree9.add(tree9Sound)
scene.add(tree9)

const tree10 = new THREE.Mesh(locGeo, treeMat)
tree10.name = "tree10"
tree10.position.set(-0.68,0.55,0)
tree10.add(tree10Sound)
scene.add(tree10)

const tree11 = new THREE.Mesh(locGeo, treeMat)
tree11.name = "tree11"
tree11.position.set(0.49,-0.82,0)
tree11.add(tree11Sound)
scene.add(tree11)

const tree12 = new THREE.Mesh(locGeo, treeMat)
tree12.name = "tree12"
tree12.position.set(0.15,0,0)
tree12.add(tree12Sound)
scene.add(tree12)

const tree13 = new THREE.Mesh(locGeo, treeMat)
tree13.name = "tree13"
tree13.position.set(-0.1,-0.59,0)
tree13.add(tree13Sound)
scene.add(tree13)

const tree14 = new THREE.Mesh(locGeo, treeMat)
tree14.name = "tree14"
tree14.position.set(0.95,0.11,0)
tree14.add(tree14Sound)
scene.add(tree14)

const tree15 = new THREE.Mesh(locGeo, treeMat)
tree15.name = "tree15"
tree15.position.set(1.15,0.45,0)
tree15.add(tree15Sound)
scene.add(tree15)

const tree16 = new THREE.Mesh(locGeo, treeMat)
tree16.name = "tree16"
tree16.position.set(0.45,0.55,0)
tree16.add(tree16Sound)
scene.add(tree16)

const tree17 = new THREE.Mesh(locGeo, treeMat)
tree17.name = "tree17"
tree17.position.set(-1.6,-1.55,0)
tree17.add(tree17Sound)
scene.add(tree17)

const tree18 = new THREE.Mesh(locGeo, treeMat)
tree18.name = "tree18"
tree18.position.set(-0.65,-1.11,0)
tree18.add(tree18Sound)
scene.add(tree18)

const tree19 = new THREE.Mesh(locGeo, treeMat)
tree19.name = "tree19"
tree19.position.set(-0.61,-0.3,0)
tree19.add(tree19Sound)
scene.add(tree19)

const tree20 = new THREE.Mesh(locGeo, treeMat)
tree20.name = "tree20"
tree20.position.set(-1.1,0.1,0)
tree20.add(tree20Sound)
scene.add(tree20)

//arrays

const recordingArray = ["rec1","rec2","rec3","rec4","rec5","rec6","rec7","rec8","rec9","rec10","rec11","rec12","rec13","rec14","rec15","rec16","rec17","rec18","rec19","rec20"]

const treeArray = ["tree1","tree2","tree3","tree4","tree5","tree6","tree7","tree8","tree9","tree10","tree11","tree12","tree13","tree14","tree15","tree16","tree17","tree18","tree19","tree20"]

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 15
scene.add(camera)

const listenerMaterial = new THREE.MeshBasicMaterial({color:0xffffff})
const listenerGeometry = new THREE.SphereGeometry(0.004, 8, 6)
const listenerCube = new THREE.Mesh(listenerGeometry,listenerMaterial)
listenerCube.scale.set(0.0001,0.0001,0.0001)
listenerCube.add(listener)
scene.add(listenerCube)

let infoShown = false

const showInfo = () =>
{
    if(infoShown === false)
    {
        document.getElementById('info').classList.add('show')
    }
    infoShown = true
    
}
const hideInfo = () =>
{
    document.getElementById('info').classList.remove('show')
}

/**
 * -------------------------HTML EVENT LISTENERS-------------------------
 */
const  Content1 = document.getElementById('accordianContent1')
const accordianContent2 = document.getElementById('accordianContent2')

document.querySelectorAll(".accordianButton1").forEach(button =>
{
    button.addEventListener('click', () =>
    {
        button.classList.toggle('accordianButton1--active')
        if(button.classList.contains('accordianButton1--active'))
        {
            accordianContent1.style.maxHeight = accordianContent1.scrollHeight + 'px'
            accordianContent2.style.maxHeight = 0
            document.getElementById('accordianButton2').classList.remove('accordianButton2--active')
            document.getElementById('accordianButton2').classList.add('accordianButton2')
        }else{
            accordianContent1.style.maxHeight = 0
        }
    })
})
document.querySelectorAll(".accordianButton2").forEach(button =>
{
    button.addEventListener('click', () =>
    {
        button.classList.toggle('accordianButton2--active')
        if(button.classList.contains('accordianButton2--active')){
            accordianContent2.style.maxHeight = accordianContent2.scrollHeight + 'px'
            accordianContent1.style.maxHeight = 0
            document.getElementById('accordianButton1').classList.remove('accordianButton1--active')
            document.getElementById('accordianButton1').classList.add('accordianButton1')
        }else{
            accordianContent2.style.maxHeight = 0
        }
    })
})


document.getElementById("enter").addEventListener('click', () =>
{
    document.getElementById("enter").classList.add('hide')
    gsap.to(camera.position, {z:5,duration:1})
    mapRotation = false
    gsap.to(mapMesh.rotation, {y: 0, duration: 1})
    document.getElementById('enter').classList.add('hide')
    document.getElementById('websiteTitle').classList.add('hide')
    document.getElementById('websiteMenu').classList.add('show')
    rec1Sound.play()
    rec2Sound.play()
    rec3Sound.play()
    rec4Sound.play()
    rec5Sound.play()
    rec6Sound.play()
    rec7Sound.play()
    rec8Sound.play()
    rec9Sound.play()
    rec10Sound.play()
    rec11Sound.play()
    rec12Sound.play()
    rec13Sound.play()
    rec14Sound.play()
    rec15Sound.play()
    rec16Sound.play()
    rec17Sound.play()
    rec18Sound.play()
    rec19Sound.play()
    tree1Sound.play()
    tree2Sound.play()
    tree3Sound.play()
    tree4Sound.play()
    tree5Sound.play()
    tree6Sound.play()
    tree7Sound.play()
    tree8Sound.play()
    tree9Sound.play()
    tree10Sound.play()
    tree11Sound.play()
    tree12Sound.play()
    tree13Sound.play()
    tree14Sound.play()
    tree15Sound.play()
    tree16Sound.play()
    tree17Sound.play()
    tree18Sound.play()
    tree19Sound.play()
})

const mapDistance = 0.2
let controlsOpen = false
let currentSound = null

let raycaster = new THREE.Raycaster()
let rayDetection = null

let camZoom = false

recordingArray.forEach(element => {
    document.getElementById(String(element)).addEventListener('click', () =>
    {
        gsap.to(camera.position, {x:eval(element).position.x,y:eval(element).position.y,z:mapDistance})
        dragState = true
        aboutOpen = false
        controlsOpen = true
        showInfo()
        gsap.to(listenerCube.scale, {x:0.9, y: 0.9, z: 0.9})
        document.getElementById('exit').classList.add('show')
        document.getElementById('about').classList.remove('show')
        document.getElementById("aboutParagraph").classList.remove('show')
        document.getElementById('controls').classList.add('show')
        currentSound = element
        camZoom = true
    })
});

treeArray.forEach(element => {
    document.getElementById(String(element)).addEventListener('click', () =>
    {
        gsap.to(camera.position, {x:eval(element).position.x,y:eval(element).position.y,z:mapDistance})
        dragState = true
        aboutOpen = false
        showInfo()
        gsap.to(listenerCube.scale, {x:0.9, y: 0.9, z: 0.9})
        document.getElementById('exit').classList.add('show')
        document.getElementById('about').classList.remove('show')
        document.getElementById("aboutParagraph").classList.remove('show')
        document.getElementById('controls').classList.add('show')
        camZoom = true
    })
});

document.getElementById("exit").addEventListener('click', () =>
{
    gsap.to(camera.position, {x:0, z:5, y:0, duration: 1})
    dragState = false
    aboutOpen = false
    hideInfo()
    gsap.to(listenerCube.scale, {x:0.00009, y: 0.00009, z: 0.00009})
    document.getElementById('exit').classList.remove('show')
    document.getElementById('about').classList.remove('show')
    document.getElementById("aboutParagraph").classList.remove('show')
    document.getElementById('controls').classList.remove('show')
    camZoom = false
})

document.querySelector(".pause").addEventListener('click', () =>
{
    eval(currentSound + "Sound").pause()
})

document.querySelector(".play").addEventListener('click', () =>
{
    eval(currentSound + "Sound").play()
})


document.querySelector(".allPlay").addEventListener('click', () =>
{
    rec1Sound.play()
    rec2Sound.play()
    rec3Sound.play()
    rec4Sound.play()
    rec5Sound.play()
    rec6Sound.play()
    rec7Sound.play()
    rec8Sound.play()
    rec9Sound.play()
    rec10Sound.play()
    rec11Sound.play()
    rec12Sound.play()
    rec13Sound.play()
    rec14Sound.play()
    rec15Sound.play()
    rec16Sound.play()
    rec17Sound.play()
    rec18Sound.play()
    rec19Sound.play()
    tree1Sound.play()
    tree2Sound.play()
    tree3Sound.play()
    tree4Sound.play()
    tree5Sound.play()
    tree6Sound.play()
    tree7Sound.play()
    tree8Sound.play()
    tree9Sound.play()
    tree10Sound.play()
    tree11Sound.play()
    tree12Sound.play()
    tree13Sound.play()
    tree14Sound.play()
    tree15Sound.play()
    tree16Sound.play()
    tree17Sound.play()
    tree18Sound.play()
    tree19Sound.play()
})

document.querySelector(".allPause").addEventListener('click', () =>
{
    rec1Sound.pause()
    rec2Sound.pause()
    rec3Sound.pause()
    rec4Sound.pause()
    rec5Sound.pause()
    rec6Sound.pause()
    rec7Sound.pause()
    rec8Sound.pause()
    rec9Sound.pause()
    rec10Sound.pause()
    rec11Sound.pause()
    rec12Sound.pause()
    rec13Sound.pause()
    rec14Sound.pause()
    rec15Sound.pause()
    rec16Sound.pause()
    rec17Sound.pause()
    rec18Sound.pause()
    rec19Sound.pause()
    tree1Sound.pause()
    tree2Sound.pause()
    tree3Sound.pause()
    tree4Sound.pause()
    tree5Sound.pause()
    tree6Sound.pause()
    tree7Sound.pause()
    tree8Sound.pause()
    tree9Sound.pause()
    tree10Sound.pause()
    tree11Sound.pause()
    tree12Sound.pause()
    tree13Sound.pause()
    tree14Sound.pause()
    tree15Sound.pause()
    tree16Sound.pause()
    tree17Sound.pause()
    tree18Sound.pause()
    tree19Sound.pause()
})

let aboutOpen = false

document.getElementById("about").addEventListener('click', () =>
{
    if(aboutOpen === false)
    {
        document.getElementById("aboutParagraph").classList.add('show')
        aboutOpen = true
        document.getElementById("about").classList.add('show')
    }
    else
    {
        document.getElementById("aboutParagraph").classList.remove('show')
        aboutOpen = false
        document.getElementById("about").classList.remove('show')
    }
})
// DRAG 
let mouseX = null
let mouseY = null
let dragDown = false
let dragState = false
let camDragPosX = null
let camDragPosY = null
let currentCamPosX = camera.position.x
let currentCamPosY = camera.position.y


document.addEventListener('mousedown', (event) =>
{
    currentCamPosX = camera.position.x
    currentCamPosY = camera.position.y

    mouseX = event.clientX - (window.innerWidth * 0.5)
    mouseY = (event.clientY - (window.innerHeight * 0.5)) * -1

    dragDown = true
})
document.addEventListener('mousemove', (event) =>
{
    camDragPosX = event.clientX - (window.innerWidth * 0.5)
    camDragPosY = (event.clientY - (window.innerHeight * 0.5)) * -1
})
document.addEventListener('mouseup', (event) =>
{
    dragDown = false
})

document.addEventListener('wheel', (event) => {
  if(camZoom === true)
  {
      if(event.deltaY < 0 && camera.position.z > 0.2)
      {
        console.log("down")
        camera.position.z -= 0.01
      }
      else if(event.deltaY > 0 && camera.position.z < 1)
      {
        console.log("up")
        camera.position.z += 0.01
      }
  }
})

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({

    canvas: canvas,
    alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

/**
 * Animate
 */
const clock = new THREE.Clock()

let mapRotation = true
let zoomAmount = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    let rayDirection = new THREE.Vector3(camera.getWorldPosition.x, camera.getWorldPosition.y, -1)
    raycaster.set(camera.position, rayDirection)
    rayDetection = raycaster.intersectObjects([rec1, rec2, rec3, rec4, rec5, rec6, rec7, rec8, rec9, rec10, rec11, rec12, rec13, rec14, rec15, rec16, rec17, rec18, rec19, rec20, tree1, tree2, tree3, tree4, tree5, tree6, tree7, tree8, tree9, tree10, tree11, tree12, tree13, tree14, tree15, tree16, tree17, tree18, tree19, tree20], true)

    if(rayDetection.length > 0)
    {
        console.log(rayDetection)
        currentSound = rayDetection[0].object.name
    }

    listenerCube.position.set(camera.position.x,camera.position.y,camera.position.z - 0.15)

    // Render
    renderer.render(scene, camera)

    if(dragDown === true && dragState === true)
    {  
        camera.position.x = currentCamPosX - (camDragPosX - mouseX) * 0.0004
        camera.position.y = currentCamPosY - (camDragPosY - mouseY) * 0.0004
    }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()